import React from "react";
import Header from "../components/Header";

function About() {
  return (
    <div>
      <Header />
      <main className="about">
        <h1>COMING SOON</h1>
      </main>
    </div>
  );
}

export default About;

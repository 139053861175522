import React from 'react'

function footer() {
  return (
    <div className='footer'>
      <a href="mailto:epurevision@gmail.com">@epurevision</a>
      <p>©2024</p>
    </div>
  )
}

export default footer
import React from "react";

function productivite() {
  return (
    <div>
      <section className="productivite">
        <div className="bg-productivite"> </div>
        <h2>productivité</h2>
        <div className="container-details">
          <div className="details">
            <div className="title-details">
              <h3>WEB</h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-computer"
              >
                <rect width="14" height="8" x="5" y="2" rx="2" />
                <rect width="20" height="8" x="2" y="14" rx="2" />
                <path d="M6 18h2" />
                <path d="M12 18h6" />
              </svg>
            </div>
            <p>Développement</p>
            <ul>
              <li>- Web design</li>
              <li>- SEO</li>
              <li>- Hébergement</li>
              <li>- E-commerce</li>
            </ul>
          </div>
          <div className="details">
            <div className="title-details">
              <h3>BRANDING</h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-pen-tool"
              >
                <path d="M15.707 21.293a1 1 0 0 1-1.414 0l-1.586-1.586a1 1 0 0 1 0-1.414l5.586-5.586a1 1 0 0 1 1.414 0l1.586 1.586a1 1 0 0 1 0 1.414z" />
                <path d="m18 13-1.375-6.874a1 1 0 0 0-.746-.776L3.235 2.028a1 1 0 0 0-1.207 1.207L5.35 15.879a1 1 0 0 0 .776.746L13 18" />
                <path d="m2.3 2.3 7.286 7.286" />
                <circle cx="11" cy="11" r="2" />
              </svg>
            </div>
            <p>Graphisme</p>
            <ul>
              <li>- Design de logo</li>
              <li>- Identité de marque</li>
              <li>- Création de contenu</li>
              <li>- Réalisation vidéo</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default productivite;

import React from "react";

function introduction() {
  return (
    <div>
      <section className="introduction">
        <h2>
          UN NOUVEAU DÉPART <br /> <span>EPUREVISION</span>
        </h2>
        <div className="text-introduction">
          <p>
            Spécialisés dans le développement web et le graphisme, nous
            fusionnons innovation et esthétique pour donner vie à vos visions.
            Du design épuré au code optimisé, chaque projet est une conception
            moderne. Confiez nous votre vision, et nous la transformerons en une
            expérience en ligne immersive, reflétant l'essence unique de votre
            entreprise. (FR)
          </p>
          <div className="line"></div>
          <p>
            Specializing in web development and graphic design, we merge
            innovation and aesthetics to bring your visions to life. From sleek
            design to optimized code, every project is a modern creation.
            Entrust us with your vision, and we will transform it into an
            immersive online experience that reflects the unique essence of your
            business. (EN)
          </p>
        </div>
        <ul className="application-phone">
          <li>ADOBE CREATIVE CLOUD</li>
          <li>FIGMA</li>
        </ul>
        <ul className="application-desktop">
          <li>INDESIGN</li>
          <li>ILLUSTRATOR</li>
          <li>PHOTOSHOP</li>
          <li>LIGHTROOM</li>
          <li>AFTER EFFECTS</li>
          <li>PREMIERE PRO</li>
          <li>FIGMA</li>
        </ul>
      </section>
    </div>
  );
}

export default introduction;
